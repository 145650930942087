import React from "react";
import calendarIcon from "assets/images/svg/calendar.svg";
import "./styles.scss";
import "react-datepicker/dist/react-datepicker.css";


const RangeDatePicker = ({ setDateRange, dateRange, ...props }) => {
	const [startDate, endDate] = dateRange ;

	return (
		<div className="range-picker-wrapper date-picker-wrapper">
			<div className="main-img">
				<img src={calendarIcon} alt="" />
			</div>
			{/*<DatePicker*/}
			{/*	selectsRange={true}*/}
			{/*	onChange={(update) => {*/}
			{/*		setDateRange(prev => update);*/}
			{/*	}}*/}

			{/*	startDate = {startDate}*/}
			{/*	endDate = {endDate}*/}
			{/*	{...props}*/}
			{/*/>*/}
		</div>
	);
};

RangeDatePicker.defaultProps = {
	dateRange: [null, null],
	setDateRange: Function,
	dateFormat: "dd.MM.yyyy",
	placeholderText: "Select a range"
};

export default RangeDatePicker;
