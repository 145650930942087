import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import cn from "classnames";

import EntityForm from "modules/entity/forms";

import { SearchContext } from "context/SearchContext";
import { api, constants } from "services";
import { storage } from "services";
import Actions from "store/actions";

import { Fields, Notifications } from "components";
import { WS_close } from "../../services/websocket";

const headerLinks = [
	{ to: "/", label: "Колл-центр" },
	{ to: "/dashboard", label: "Дашбоард" },
	{ to: "/handbook", label: "История звонков" },
	{ to: "/broadcast", label: "СМС рассылка" },
	{ to: "/customer", label: "Клиенты" },
	{ to: "/application", label: "Заявки" },
	{ to: "/trade", label: "Торговля" },
	{ to: "/tasks", label: "Задачи" }
];

const Header = () => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.auth.user);
	const history = useHistory();
	const [searchText, setSearch] = useState("");
	const [active, setActive] = useState(false);
	const [activeLink, setActiveLink] = useState(history.location.pathname);

	history.listen(location => {
		setActiveLink(location.pathname);
	});

	const { setSearchText } = useContext(SearchContext);

	const handleChange = e => {
		const value = e.target.value
			.replace(/[(),./!@#$%^&=_{}*?":;~<+>']/g, "")
			.replace(/-/g, "")
			.replace(" ", "");
		setSearch(value);
	};

	const handleSearch = () => {
		if (history.location.pathname !== "/handbook")
			history.push("/handbook");
		setSearchText(searchText);
	};

	const handleKeyPress = e => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	const handleSubmit = event => {
		event.preventDefault();
	};

	const openCallWindow = () => {
		const body = document.querySelector("body");
		if (body.className.includes("call_open")) {
			body.removeAttribute("class");
		} else {
			body.setAttribute("class", "call_open");
		}
	};

	const cleanStorage = () => {
		["hasMicrophone", "microphonePermission"].forEach(item => {
			storage.remove(item);
		});
	};

	const handleLogout = event => {
		storage.remove("token");
		dispatch(Actions.auth.GetMeRequest.failure());
		api.request
			.post("/user/change-status", { type: constants.TYPE_NOT_WORKING })
			.catch(error => console.log(error));
		WS_close();
		cleanStorage();
	};

	const showLogoutButton = event => {
		const sidebarBtn = document.querySelector(".sidebar-btn");
		sidebarBtn.style.display =
			sidebarBtn.style.display === "none" ? "flex" : "none";
	};

	return (
		<header className="header">
			<div className="header-inner">
				<Link to="/" className="sidebar-logo">
					<img src={require("assets/images/svg/logo.svg")} alt="" />
				</Link>
				<nav className={`header-nav ${active && "active"}`}>
					<ul className="header-list">
						{headerLinks.map((item, i) => {
							return (
								<li key={i}>
									<Link
										to={item.to}
										className={cn("header-link", {
											active: activeLink === item.to
										})}
										onClick={() => setActive(false)}>
										{item.label}
									</Link>
								</li>
							);
						})}

						{/* {(get(user, "role") === constants.ROLE_MANAGER ||
							get(user, "role") === constants.ROLE_ADMIN) && (
							<li>
								<Link
									to="/notifications"
									className={cn("header-link", {
										active: activeLink === "/notifications"
									})}
									onClick={() => setActive(false)}>
									Напоминания
								</Link>
							</li>
						)} */}
					</ul>

					{/*<form*/}
					{/*	className="header-form header-mob"*/}
					{/*	onSubmit={handleSubmit}>*/}
					{/*	/!*<label className="header-label">*!/*/}
					{/*	/!*	<div className="header-label__title">Search by</div>*!/*/}
					{/*	/!*	<Fields.CustomSelect />*!/*/}
					{/*	/!*</label>*!/*/}

					{/*	<label className="header-label">*/}
					{/*		<div className="header-search">*/}
					{/*			<img*/}
					{/*				className="header-search__icon"*/}
					{/*				src={require("assets/images/svg/search.svg")}*/}
					{/*				alt=""*/}
					{/*			/>*/}
					{/*			<input*/}
					{/*				className="header-control"*/}
					{/*				type="text"*/}
					{/*				placeholder="Поиск ..."*/}
					{/*				onChange={handleChange}*/}
					{/*				onKeyPress={handleKeyPress}*/}
					{/*			/>*/}
					{/*			<button*/}
					{/*				className="header-btn"*/}
					{/*				type="submit"*/}
					{/*				onClick={() => setSearchText(searchText)}>*/}
					{/*				Поиск*/}
					{/*			</button>*/}
					{/*		</div>*/}
					{/*	</label>*/}
					{/*</form>*/}
				</nav>

				<div className="header__actions">
					<div
						id="call-button"
						className="d-flex align-items-center"
						title="Позвонить">
						<button className="call-btn" onClick={openCallWindow}>
							<img
								src={require("assets/images/svg/call-manual.svg")}
								alt=""
							/>
						</button>
					</div>

					<Notifications />

					<div>
						<EntityForm.Main
							entity="change-status"
							name="change-status"
							method="post"
							url="/user/change-status"
							fields={[
								{
									name: "type",
									value:
										constants.getType(
											get(user, "lastStatus.type")
										) || constants.types[0],
									onSubmitValue: value => get(value, "id")
								}
							]}>
							{({ setFieldValue, values }) => (
								<Fields.CustomSelect
									options={constants.types}
									optionLabel="label"
									optionValue="id"
									value={values.type}
									handleChange={option => {
										setFieldValue("type", option);
										api.request
											.post("/user/change-status", {
												type: get(option, "id")
											})
											.catch(err => console.log(err));
									}}
								/>
							)}
						</EntityForm.Main>
					</div>

					{/*<form className="header-form" onSubmit={handleSubmit}>*/}
					{/*	/!*<label className="input__wrapper select__wrapper">*!/*/}
					{/*	/!*	<span className="input__label"> Search by </span>*!/*/}
					{/*	/!*	<Fields.CustomSelect />*!/*/}
					{/*	/!*</label>*!/*/}

					{/*	<label className="header-label">*/}
					{/*		<div className="header-search">*/}
					{/*			<img*/}
					{/*				className="header-search__icon"*/}
					{/*				src={require("assets/images/svg/search.svg")}*/}
					{/*				alt=""*/}
					{/*			/>*/}
					{/*			<input*/}
					{/*				className="header-control"*/}
					{/*				type="search"*/}
					{/*				placeholder="Поиск ..."*/}
					{/*				value={searchText}*/}
					{/*				onChange={handleChange}*/}
					{/*				onKeyPress={handleKeyPress}*/}
					{/*			/>*/}
					{/*			<button*/}
					{/*				className="header-btn"*/}
					{/*				type="submit"*/}
					{/*				onClick={handleSearch}>*/}
					{/*				Поиск*/}
					{/*			</button>*/}
					{/*		</div>*/}
					{/*	</label>*/}
					{/*</form>*/}

					<div
						className={`header-burger ${active && "active"}`}
						onClick={() => setActive(!active)}>
						<span />
					</div>

					<div
						className={"profile-button"}
						style={{ position: "relative", cursor: "pointer" }}
						onClick={showLogoutButton}>
						<div>
							{user.operator_number} Профиль
						</div>
						<div>
							<button
								title="Выйти из аккаунта"
								className="sidebar-btn btn_red"
								style={{
									position: "absolute",
									top: "30px",
									right: 0,
									display: "none",
									width: "auto",
									height: "50px",
									borderRadius: "0px",
									color: "white"

								}}
								onClick={handleLogout}>
								<img
									src={require("assets/images/svg/logout.svg")}
									style={{width: "24px"}}
									alt=""
								/> Выйти из аккаунта
							</button>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
