import JsSIP from "jssip";
import { toast } from "react-toastify";
import config from "config";

export class SipService {
	ua = null;
	session = null;
	cb = {};
	reconnectInterval = null;
	userExtension = null; // hozirgi tizimni ishlatvotgan operatorni nomeri
	maxReconnectCount = 10;
	disconnectCount = 0;
	isIntentionalDisconnect = false;

	constructor(cb) {
		this.cb = cb;
	}

	init(userExtension) {
		this.userExtension = userExtension;
		this.start();
	}

	async start() {
		if (this.ua) {
			console.log("SIP service is already running.");
			return;
		}
		const socket = new JsSIP.WebSocketInterface(config.SIP_URL);

		const configuration = {
			sockets: [socket],
			uri: `sip:${this.userExtension}@ws.mecall.uz`,
			password: "12345",
			session_timers: false
		};

		this.ua = new JsSIP.UA(configuration);

		this.ua.on("connected", () => {
			console.log("connected");
			this.isIntentionalDisconnect = false;
			this.disconnectCount = 0;
		});

		this.ua.on("disconnected", () => {
			console.log("disconnected");
			if (
				!this.isIntentionalDisconnect &&
				this.disconnectCount < this.maxReconnectCount
			) {
				this.disconnectCount++;
				this.reconnect();
			}
		});

		this.ua.on("newRTCSession", data => {
			this.session = data.session;

			if (this.session.direction === "incoming") {
				this.cb.onAcceptCall(this.session);
				this.controlSessionState();
				console.log("invitation accepted");
			}
		});

		this.ua.start();

		return this.ua;
	}

	async stop() {
		this.isIntentionalDisconnect = true;
		this.disconnectCount = 0;

		if (this.session) {
			this.session.terminate();
			console.log("session terminated");
		}

		if (this.ua) {
			this.ua.stop();
			console.log("user agent stopped succesfully");
		}

		this.ua = null;
		this.session = null;
		this.isIntentionalDisconnect = false;
	}

	async reconnect() {
		if (
			this.reconnectInterval ||
			this.session ||
			this.disconnectCount > this.maxReconnectCount
		) {
			return;
		}

		this.reconnectInterval = setInterval(async () => {
			console.log("Attempting to reconnect...");
			try {
				await this.stop();
				await this.start();
				clearInterval(this.reconnectInterval); // Clear the interval after a successful reconnection
				this.reconnectInterval = null; // Reset the reconnectInterval
			} catch (error) {
				console.error("Error while trying to reconnect:", error);
			}
		}, 3000);
	}

	closeSocket() {
		this.isIntentionalDisconnect = true;
		this.stop();
	}

	acceptCall() {
		if (this.session) {
			this.cb.onAnswered();
			this.session.answer();
			this.controlSessionState();
		}
	}

	muteOutsideCall() {
		this.cb.onMute();
	}

	toggleMuteIncomingCall(enable) {
		if (this.session) {
			const pc = this.session.connection;
			if (pc) {
				pc.getSenders().forEach(sender => {
					if (sender.track && sender.track.kind === "audio") {
						sender.track.enabled = enable;
						this.cb.toggleMuteIncomingCall();
						console.log(
							enable
								? "Microphone has been muted"
								: "Microphone has been unmuted"
						);
					}
				});
			} else {
				console.warn(
					"Cannot mute/unmute because the call is not established"
				);
				toast.warn(
					"Ovozni o‘chirib/yoqib bo‘lmaydi, chunki faol qo‘ng‘iroq yo‘q"
				);
			}
		} else {
			console.warn("Cannot mute/unmute because there is no active call");
			toast.warn(
				"Ovozni o‘chirib/yoqib bo‘lmaydi, chunki faol qo‘ng‘iroq yo‘q"
			);
		}
	}

	toggleHold(enable) {
		if (this.session) {
			this.isOnHold = enable == null ? !this.isOnHold : enable;
			this.cb.onToggleHold(this.isOnHold);

			if (this.isOnHold) {
				this.session.hold();
				console.log("Call has been put on hold");
			} else {
				this.session.unhold();
				console.log("Call has been taken off hold");
			}
		} else {
			console.log("No active call to toggle hold");
			toast.warn("Kutish uchun faol qo‘ng‘iroq yo‘q");
		}
	}

	call(phoneNumber) {
		const target = `sip:${phoneNumber}@192.168.31.100`;

		const eventHandlers = {
			progres: () => {
				this.cb.onRinging();
			},
			accepted: () => {
				this.cb.onAnswered();
			},
			failed: e => {
				this.cb.onHangup();
				console.log("call failed: ", e);
			},
			ended: () => {
				this.cb.onHangup();
				console.log("call ended");
			}
		};

		const options = {
			eventHandlers,
			mediaConstraints: { audio: true, video: false }
		};

		this.session = this.ua.call(target, options);

		this.controlSessionState();
	}

	controlSessionState() {
		if (this.session.connection) {
			this.session.connection.addEventListener("track", event => {
				console.log('stream set qilindi');
				const remoteAudio = document.getElementById("remote-audio");
				console.log(event);
				remoteAudio.srcObject = event.streams[0];
				remoteAudio.play();
			});
		}

		this.session.on("ended", () => {
			this.cb.onHangup();
			this.cleanupMedia();
			this.session = null;
		});

		this.session.on("failed", () => {
			this.cb.onHangup();
			this.cleanupMedia();
			this.session = null;
		});
	}

	endCall() {
		this.cb.onHangup();
		if (this.session) {
			console.log("end call session bo");
			this.session.terminate();
		} else {
			console.log("end call session yoq");
			this.session.bye();
		}
	}

	cleanupMedia() {
		const remoteAudio = document.getElementById("remote-audio");
		remoteAudio.srcObject = null;
	}

	handleRefer(referral) {
		// Check if there is an ongoing call
		if (this.session) {
			// Reject the referral if there is an ongoing call
			referral.reject();
			return;
		}

		// Save the referral object for later use
		this.session = referral;
	}

	transferCall(phoneNumber) {
		if (this.session) {
			const target = `sip:${phoneNumber}@192.168.31.100`;

			const eventHandlers = {
				requestSucceeded: () => {
					this.endCall();
				},
				requestFailed: error => {
					console.log("transfer failed: ", error);
				}
			};

			this.session.refer(target, { eventHandlers });
		}
	}
}
