import React, { useEffect } from 'react'

const useOnlineStatus = () => {
  const currentStatus = typeof window !== 'undefined' ? navigator.onLine : true;
  const [onlineStatus, setOnlineStatus] = React.useState(currentStatus);

  useEffect(()=>{
    const handleStatusChange = () => {
        setOnlineStatus(navigator.onLine);
    }

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
        window.removeEventListener('online', handleStatusChange);
        window.removeEventListener('offline', handleStatusChange);
    }

  },[])

  return onlineStatus;
}

export default useOnlineStatus