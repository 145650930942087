import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import { toast } from "react-toastify";

import { LoadMoreVisible, Spinner, Modal } from "components";
import EntityForm from "modules/entity/forms";
import EntityContainer from "modules/entity/containers";
import Form from "modules/entity/actions";
import ModalActions from "store/actions/modal";
import { constants } from "services";
import { useModal } from "hooks/useModal";

import ModalForm from "./Form/Customer.Form";
import FormFields from "./Form/Customer.FormFields";
import CallHistory from "./CallHistory/Item";

const CustomerHandler = () => {
	const dispatch = useDispatch();
	const [formData, setFormData] = useState(null);
	const [countryId, setCountryId] = useState();
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const {
		isOpen,
		handleOpen,
		handleClose,
		handleCloseWithoutChange
	} = useModal({
		onClose: () => {
			setFormData(null);
		}
	});

	const [data, setData] = useState();
	const [isPhoneHistoryOpen, setIsPhoneHistoryOpen] = useState(false);
	const [showHistoryCall, setShowHistoryCall] = useState(false);
	const [callType, setCallType] = useState(1);

	window.handleOpen = data => {
		handleOpen();
		setData(data);
	};
	window.handleOpenCallHistory = data => {
		setShowHistoryCall(true);
		handleOpen();
		setData(data);
	};

	window.handleOpenCallHistoryReview = data => {
		setShowHistoryCall(true);
		handleOpen();
		setData(data);
	};

	function loadMore(e, meta, isFetched) {
		const items = e.target.getElementsByClassName("history-items");
		const last = items[items.length - 1];
		const is_bottom =
			e.target.getBoundingClientRect().bottom + 100 >=
			last.getBoundingClientRect().bottom;
		if (
			is_bottom &&
			get(meta, "currentPage") < get(meta, "pageCount") &&
			isFetched
		) {
			setPage(page + 1);
		}
	}

	const handlePhoneHistory = () => {
		setIsPhoneHistoryOpen(prev => !prev);
	};

	useEffect(() => {
		setLoading(true);
		if (get(data, "phone", "")) {
			if (showHistoryCall) {
				fetchCallHistory(get(data, "id", ""));
			} else {
				fetchUser(get(data, "phone", ""));
			}
		}
		setLoading(false);
	}, [data]);

	const fetchUser = phone => {
		dispatch(
			Form.FormDefault.request({
				url: "customer/search",
				method: "get",
				params: {
					extra: {
						phone
					},
					include:
						"country,region,district,address,source"
				},
				cb: {
					success: data => {
						setFormData(get(data, "data"));
						setCountryId(get(data.data, "country.id"));
					},
					error: err => {
						setFormData(null);
					},
					finally: () => {
						setLoading(false);
					}
				}
			})
		);
	};

	const fetchCallHistory = id => {
		dispatch(
			Form.FormDefault.request({
				url: `call-history/${id}`,
				method: "get",
				params: {
					include:
						"customer.country,customer.region,customer.district,customer.address,customer.source,services,services.category.parent,category,additional_number,customer.queue_number"
				},
				cb: {
					success: data => {
						const {
							type,
							name,
							gender,
							birthdate,
							country,
							region,
							district,
							address,
							source,
							phone,
							additionalNumber,
							id
						} = get(data, "data.customer");

						setFormData({
							...get(data, "data"),
							call_history_id: get(data, "data.id"),
							type,
							name,
							gender,
							birthdate,
							country,
							region,
							district,
							address,
							source,
							phone,
							additionalNumber,
							id
						});
						setCountryId(get(data.data, "country.id"));
					},
					error: err => {
						setFormData(null);
					},
					finally: () => {
						setLoading(false);
					}
				}
			})
		);
	};

	return (
		<Modal isOpen={isOpen} toggle={() => {}} size={1190}>
			{loading ? (
				<Spinner id="modal-loader" />
			) : (
				<div className="modal-wrapper">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">
							{showHistoryCall ? "Редактировать" : "Добавить"}{" "}
							Историю
						</h5>
						{/* <div
							onClick={handlePhoneHistory}
							className="call-history">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M21.9999 16.9201V19.9201C22.0011 20.1986 21.944 20.4743 21.8324 20.7294C21.7209 20.9846 21.5572 21.2137 21.352 21.402C21.1468 21.5902 20.9045 21.7336 20.6407 21.8228C20.3769 21.912 20.0973 21.9452 19.8199 21.9201C16.7428 21.5857 13.7869 20.5342 11.1899 18.8501C8.77376 17.3148 6.72527 15.2663 5.18993 12.8501C3.49991 10.2413 2.44818 7.27109 2.11993 4.1801C2.09494 3.90356 2.12781 3.62486 2.21643 3.36172C2.30506 3.09859 2.4475 2.85679 2.6347 2.65172C2.82189 2.44665 3.04974 2.28281 3.30372 2.17062C3.55771 2.05843 3.83227 2.00036 4.10993 2.0001H7.10993C7.59524 1.99532 8.06572 2.16718 8.43369 2.48363C8.80166 2.80008 9.04201 3.23954 9.10993 3.7201C9.23656 4.68016 9.47138 5.62282 9.80993 6.5301C9.94448 6.88802 9.9736 7.27701 9.89384 7.65098C9.81408 8.02494 9.6288 8.36821 9.35993 8.6401L8.08993 9.9101C9.51349 12.4136 11.5864 14.4865 14.0899 15.9101L15.3599 14.6401C15.6318 14.3712 15.9751 14.1859 16.3491 14.1062C16.723 14.0264 17.112 14.0556 17.4699 14.1901C18.3772 14.5286 19.3199 14.7635 20.2799 14.8901C20.7657 14.9586 21.2093 15.2033 21.5265 15.5776C21.8436 15.9519 22.0121 16.4297 21.9999 16.9201Z"
									stroke="#08305ec5"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M19 1C21.2092 1 23 2.7908 23 5C23 7.2092 21.2092 9 19 9C16.7908 9 15 7.2092 15 5C15 2.7908 16.7908 1 19 1ZM19 1.558C18.0871 1.558 17.2116 1.92064 16.5661 2.56614C15.9206 3.21164 15.558 4.08712 15.558 5C15.558 5.91288 15.9206 6.78836 16.5661 7.43386C17.2116 8.07936 18.0871 8.442 19 8.442C19.9129 8.442 20.7884 8.07936 21.4339 7.43386C22.0794 6.78836 22.442 5.91288 22.442 5C22.442 4.08712 22.0794 3.21164 21.4339 2.56614C20.7884 1.92064 19.9129 1.558 19 1.558ZM18.628 3.2324C18.782 3.2324 18.9068 3.3576 18.9068 3.5116V5.4652H20.8604C20.9326 5.46793 21.0009 5.49852 21.051 5.55055C21.1011 5.60257 21.129 5.67198 21.129 5.7442C21.129 5.81642 21.1011 5.88583 21.051 5.93785C21.0009 5.98988 20.9326 6.02047 20.8604 6.0232H18.628C18.554 6.0232 18.4829 5.99378 18.4306 5.94142C18.3782 5.88906 18.3488 5.81805 18.3488 5.744V3.512C18.3488 3.3576 18.4736 3.2324 18.628 3.2324Z"
									fill="#B3BAC3"
									stroke="#08305ec5"
									strokeWidth="0.5"
								/>
							</svg>
						</div> */}
					</div>
					<div className="modal-body">
						<div className="row">
							<div
								style={{
									paddingBottom: "20px"
								}}>
								<EntityForm.Main
									entity="patientData"
									name="patientData"
									url={`/call-history`}
									method={"post"}
									fields={FormFields({
										data,
										formData,
										callType
									})}
									className="caller"
									onError={() => {
										toast.error("Что то пошло не так", {
											autoClose: 5000
										});
									}}
									onSuccess={(
										responseData,
										formValues,
										resetForm
									) => {
										dispatch(ModalActions.success());
										setShowHistoryCall(prev => false);
										handleClose();
										resetForm();
										toast.success("Успешно!", {
											autoClose: 5000
										});
									}}>
									{({
										handleSubmit,
										values,
										errors,
										setFieldValue,
										handleReset
									}) => {
										return (
											<ModalForm
												{...{
													isManual: get(
														data,
														"isManual",
														false
													),
													disabled: get(
														data,
														"disabled",
														false
													),
													values,
													setFieldValue,
													formData,
													fetchUser,
													handleSubmit,
													handleClose,
													handleReset,
													countryId,
													setCountryId,
													showHistoryCall,
													setShowHistoryCall,
													handleCloseWithoutChange,
													setCallType,
													callType
												}}
											/>
										);
									}}
								</EntityForm.Main>
							</div>
							<div
								style={{
									width: 1100
								}}
								className={`history-container ${
									isPhoneHistoryOpen ? "active" : ""
								}`}>
								<div className="main-card caller-shadow">
									<div className="caller-title">
										Последние звонки
									</div>
									<div className="main-bottom caller-border">
										{formData && (
											<EntityContainer.All
												entity="customerCallHistory"
												name="customerCallHistory"
												url={`/customer/${get(
													formData,
													"id"
												)}/history`}
												params={{
													limit: 10,
													page,
													filter: {
														status: [
															constants.STATUS_END,
															constants.STATUS_SELF
														]
													}
												}}
												metaKey={data => {
													return get(data, "meta");
												}}
												appendData={true}
												onSuccess={() => {
													dispatch({
														type: "open",
														payload: {}
													});
												}}>
												{({
													items,
													isFetched,
													meta
												}) => (
													<>
														<div
															className="history"
															onScroll={e =>
																loadMore(
																	e,
																	meta,
																	isFetched,
																	true
																)
															}>
															{(items || []).map(
																(h, i) => (
																	<CallHistory
																		key={
																			h.id
																		}
																		history={
																			h
																		}
																		status={
																			h.status
																		}
																		operator={
																			h.operator
																		}
																		loadMore={
																			loadMore
																		}
																	/>
																)
															)}

															{isFetched &&
																get(
																	meta,
																	"currentPage"
																) <
																	get(
																		meta,
																		"pageCount"
																	) && (
																	<LoadMoreVisible
																		setPage={() =>
																			setPage(
																				page +
																					1
																			)
																		}
																	/>
																)}
														</div>
													</>
												)}
											</EntityContainer.All>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default CustomerHandler;
