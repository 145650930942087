import React from "react";
import { time } from "services";

import { ReactComponent as DeleteIcon } from "assets/images/svg/close.svg";

export const NotificationCard = ({
	id,
	userFullName,
	userPhone,
	comment,
	date,
	handleMenuClose,
	deleteNotification
}) => {
	return (
		<div className="notification__card">
			<button
				className="notification__delete"
				onClick={() => deleteNotification(id)}>
				<DeleteIcon />
			</button>

			<div className="notification__info">
				<h4 className="notification__user">{userFullName}</h4>
				<p className="notification__phone">{userPhone}</p>
				<div className="notification__user">{time.to(date)}</div>
				<div className="notification__description">{comment}</div>
			</div>

			<button
				style={{ margin: 0 }}
				className="call-keyboard__call"
				id="manual-call-btn"
				type="button"
				onClick={() => {
					window.makeCallFromOutside(userPhone);
					handleMenuClose();
				}}>
				<img
					src={require("assets/images/svg/call-manual.svg")}
					alt=""
				/>
			</button>
		</div>
	);
};
