import React, { useEffect, useState } from "react";
import { get } from "lodash";
import CustomSelect from "../Fields/Select/Select";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function CustomPaginate({
	meta,
	isFetched,
	setAppendData,
	page,
	setPage,
	perPage,
	setPerPage
}) {
	const [paginationConf, setPaginationConf] = useState({
		pageRange: 6,
		marginRange: 1
	});

	const history = useHistory();
	const location = useLocation();

	function loadMore2(meta, isFetched, clickAction) {
		const { selected } = clickAction;
		setAppendData(true);
		const items = document.getElementsByClassName("content-table");
		if (
			items.length &&
			isFetched &&
			get(meta, "currentPage") <= get(meta, "pageCount") &&
			get(meta, "currentPage") > 0
		) {
			setPage(prev => parseInt(selected + 1));
		}
		history.push(`?page=${selected+1}`)
	}
	useEffect(() => {
		setPage(prev => 1);
	}, [perPage]);

	useEffect(() => {
		const handleResize = () => {
			const isMobile = window.matchMedia("(max-width: 768px)").matches;

			// Change props based on responsive width
			const pageRange = isMobile ? 0 : 1;
			const marginRange = isMobile ? 0 : 0;

			setPaginationConf(prev => ({
				...prev,
				pageRange,
				marginRange
			}));
		};

		window.addEventListener("resize", handleResize);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}, [page]);
	return (
		<div className={"cs-paginate-parent"}>
			{meta && (
				<div className="cs-paginate-left">
					Total data{" "}
					<span>
						{perPage * (page - 1) + 1} -{" "}
						{perPage * page <= meta.totalCount
							? perPage * page
							: meta.totalCount}{" "}
						/ {meta && meta.totalCount}
					</span>
				</div>
			)}

			<ReactPaginate
				className={"cs-paginate"}
				breakLabel="..."
				nextLabel={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M10 16L14 12L10 8" stroke="#20383D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>}
				onPageChange={prop => loadMore2(meta, isFetched, prop)}
				// onPageChange={handlePageClick}
				// forcePage={page - 1}
				pageRangeDisplayed={paginationConf.pageRange}
				marginPagesDisplayed={paginationConf.marginRange}
				pageCount={meta ? meta.pageCount : 0}
				previousLabel={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M14 16L10 12L14 8" stroke="#20383D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>}
				renderOnZeroPageCount={null}
				// onClick={(props)=>loadMore2(meta,isFetched,props , items)}
			/>

			<div className="cs-paginate-right">
				<span>Page size </span>
				<CustomSelect
					options={[
						{
							value: 20
						},
						{
							value: 40
						}
					]}
					value={String(perPage)}
					optionLabel={"value"}
					placeholder={String(perPage)}
					handleChange={option => setPerPage(option.value)}
					isSearchable={false}
				/>
			</div>
		</div>
	);
}

export default CustomPaginate;
