import { useEffect, useState } from 'react';

const useMicrophoneCheck = () => {
	const [hasMicrophone, setHasMicrophone] = useState(false);
	const [userPermittedMicrophone, setUserPermittedMicrophone] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const setLocalStoragePermission = (value) => {
		if(typeof value === 'boolean'){
			localStorage.setItem('microphonePermission', `${value}`);
	}}

	const getLocalStoragePermission = () => {
		return JSON.parse(localStorage.getItem('microphonePermission')) || false;
	}
	const handlePermission = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			setUserPermittedMicrophone(true);
			stream.getTracks().forEach(track => track.stop());
			setLocalStoragePermission(true);
			return true;
		} catch (error) {
			setLocalStoragePermission(false);
			setUserPermittedMicrophone(false);
			console.error('Mikrofonni tekshirishda xatolik yuz berdi:', error);
			return false;
		}
	};

	const handleCloseMicrophone = () => {
		setShowModal(prev=>false);
	}

	const handleOpenMicrophone = () => {
		setShowModal(prev=>true);
	}
	useEffect(() => {
		const permitMic = getLocalStoragePermission();
		const hasMic = JSON.parse(localStorage.getItem('hasMicrophone')) || false;
		setUserPermittedMicrophone(permitMic);
		setHasMicrophone(hasMic);
	}, []);

	useEffect(() => {
		const checkMicrophone = async () => {
			try {
				const devices = await navigator.mediaDevices.enumerateDevices();
				const hasAudioInput = devices.some(device => device.kind === 'audioinput');
				localStorage.setItem('hasMicrophone', JSON.stringify(hasAudioInput));
				setHasMicrophone(hasAudioInput);
			} catch (error) {
				localStorage.setItem('hasMicrophone', JSON.stringify(false));
				console.error('Mikrofonni tekshirishda xatolik yuz berdi:', error);
			}
		};

		const handleDeviceChange = async () => {
			await checkMicrophone();
		};

		checkMicrophone();
		navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

		return () => {
			navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
		};
	}, []);

	useEffect(() => {
		if (!hasMicrophone) {
			setShowModal(prev=>true);
		}
	}, [hasMicrophone]);

	useEffect(() => {
		if (hasMicrophone && !userPermittedMicrophone) {
			setShowModal(prev=>true);
		}else if (hasMicrophone && userPermittedMicrophone) {
			setShowModal(prev=>false);
		}
	}, [userPermittedMicrophone]);

	return { hasMicrophone, userPermittedMicrophone, showModal, handlePermission , handleCloseMicrophone,handleOpenMicrophone };
};

export default useMicrophoneCheck;