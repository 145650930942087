import get from "lodash/get";

import config from "config";

let lastSavedToken = null;
// let lastSavedCallHistoryId = null;

let ws = null;
let reconnectTimeout;
export function WS_connect(operator_number) {
	WS_close();
	ws = new WebSocket(config.WS_API);
	window.readyState = ws.readyState;

	ws.addEventListener("open", event => {
		window.readyState = ws.readyState;
	});

	ws.addEventListener("message", res => {
		const { data, token, type, message } = JSON.parse(res.data);
		const phone = get(data, "phone");
		if (!phone && message !== "success") {
			const auth = {
				operator_number: operator_number,
				token: token || lastSavedToken,
				type: 1
			};
			ws.send(JSON.stringify(auth));
		}

		if (type === 2) {
			window.handleOpen({
				phone,
				...data,
				// call_history_id: lastSavedCallHistoryId || get(data, "call_history_id")
				call_history_id: get(data, "call_history_id")
			});
			window.client_number = phone;
		}
		if (type === 3 || type === 4) {
			if (
				window.callReceived &&
				get(data, "operator_number") &&
				Number(operator_number) !== Number(get(data, "operator_number"))
			) {
				window.callReceived();
			}
		}

		if (token) lastSavedToken = token;
		// if (get(data, "call_history_id")) lastSavedCallHistoryId = get(data, "call_history_id")
	});

	ws.addEventListener("close", event => {
		if (!event.wasClean) {
			if (reconnectTimeout) clearTimeout(reconnectTimeout);
			reconnectTimeout = setTimeout(() => {
				WS_connect(operator_number);
			}, 3000);
		}
	});

	ws.addEventListener("error", event => {
		ws.close();
	});

	return ws;
}

export function WS_close() {
	if (ws) {
		if (reconnectTimeout) {
			clearTimeout(reconnectTimeout);
			reconnectTimeout = null;
		}
		ws.close();
		ws = null;
	}
}
