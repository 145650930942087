import React from "react";
import Fields from "components/Fields";
import get from "lodash/get";

import { Field } from "formik";

import { helpers } from "services";
import { genderOptions } from "services/helpers";

const ModalFormLegal = ({ values, setFieldValue, fetchUser, disabled }) => {
	return (
		<>
			<div className="row">
				<div className="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<label className="caller-label">
						<div className="caller-name">
							Название организации{" "}
							<span style={{ color: "red", fontSize: 20 }}>
								*
							</span>
						</div>
						<Field
							component={Fields.TextInput}
							name="name"
							type="text"
							size="large"
							className="caller-control"
							disabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="caller-label">
						<div className="caller-name">
							Категория деятельности{" "}
							<span style={{ color: "red", fontSize: 20 }}>
								*
							</span>
						</div>
						<Field
							component={Fields.TextInput}
							name="activity_category"
							type="text"
							size="large"
							className="caller-control"
							disabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Страна</div>
						<Field
							component={Fields.AsyncSelect}
							onChange={() => {
								setFieldValue("region_id", null);
								setFieldValue("district_id", null);
							}}
							isSearchable={true}
							name="country_id"
							size="large"
							optionLabel="name"
							loadOptionsUrl="/country"
							loadOptionsParams={search => ({
								filter: { name: search }
							})}
							isDisabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Область</div>
						<Field
							component={Fields.AsyncSelect}
							onChange={() => setFieldValue("district_id", null)}
							isSearchable={true}
							name="region_id"
							size="large"
							optionLabel="name"
							placeholder="Не задано"
							isDisabled={!get(values, "country_id")}
							loadOptionsUrl="/region"
							loadOptionsParams={search => ({
								filter: {
									name: search,
									country_id: get(values, "country_id.id")
								}
							})}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Город/Район</div>
						<Field
							component={Fields.AsyncSelect}
							isSearchable={true}
							key={get(values, "region_id.name")}
							name="district_id"
							size="large"
							optionLabel="name"
							placeholder="Не задано"
							isDisabled={!values.region_id}
							loadOptionsUrl={`/district`}
							loadOptionsParams={search => ({
								filter: {
									name: search,
									region_id: get(values, "region_id.id")
								}
							})}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="caller-label">
						<div className="caller-name">Адрес</div>
						<Field
							component={Fields.TextInput}
							name="address"
							type="text"
							size="large"
							className="caller-control"
							loadOptionsUrl="/address"
							isDisabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="caller-label">
						<div className="caller-name">
							Контактное лицо{" "}
							<span style={{ color: "red", fontSize: 20 }}>
								*
							</span>
						</div>
						<Field
							component={Fields.TextInput}
							name="contact_person_name"
							type="text"
							size="large"
							className="caller-control"
							isDisabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Пол контактного лица</div>
						<Field
							component={Fields.FormikSelect}
							name="gender"
							size="large"
							optionLabel="label"
							optionValue="value"
							options={genderOptions}
							required
							placeholder=""
							className={{
								className: "modal-select-container",
								classNamePrefix: "modal-select"
							}}
							isDisabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">
							Должность в организации
						</div>
						<Field
							component={Fields.FormikSelect}
							name="contact_person_position"
							size="large"
							optionLabel="label"
							optionValue="value"
							placeholder="Не задано"
							className={{
								className: "modal-select-container",
								classNamePrefix: "modal-select"
							}}
							isDisabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">
							Причина звонка{" "}
							<span style={{ color: "red", fontSize: 20 }}>
								*
							</span>
						</div>
						<Field
							name="category_id"
							component={Fields.AsyncSelect}
							isClearable={true}
							isSearchable={true}
							optionLabel={option => {
								if (option === null) return null;
								return `${get(option, "name.uz")}`;
							}}
							size="large"
							placeholder="Не задано"
							cacheOptions={false}
							loadOptionsUrl="category/slug/call-category/child"
							loadOptionsParams={search => ({
								filter: { name: search }
							})}
							isDisabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Канал рекламы</div>
						<Field
							component={Fields.AsyncSelect}
							isSearchable={true}
							optionLabel={option => get(option, "name.uz")}
							name="source_id"
							placeholder="Не задано"
							size="large"
							loadOptionsUrl="/source"
							loadOptionsParams={search => ({
								filter: { name: search }
							})}
							isDisabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Реклама</div>
						<Field
							component={Fields.AsyncSelect}
							isSearchable={true}
							isDisabled={get(values, "source_id.id")}
							optionLabel={option => get(option, "name.uz")}
							name="sub_source_id"
							size="large"
							loadOptionsUrl="/source"
							placeholder="Не задано"
							loadOptionsParams={search => ({
								filter: {
									name: search,
									parent_id: get(values, "source_id.id")
								}
							})}
						/>
					</label>
				</div>

				<div className="col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Категория</div>
						<Field
							component={Fields.AsyncSelect}
							isClearable={true}
							isSearchable={true}
							onChange={() => {
								setFieldValue("service_child", null);
								setFieldValue("service_ids", null);
							}}
							optionLabel={option => {
								if (option === null) return null;
								return `${get(option, "name.uz")}`;
							}}
							name="service_parent"
							placeholder="Не задано"
							size="large"
							cacheOptions={false}
							loadOptionsUrl="category/parent"
							loadOptionsParams={search => ({
								filter: {
									name: search,
									not_id: 1
								}
							})}
							isDisabled={disabled}
						/>
					</label>
				</div>

				<div className="col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Подкатегория</div>
						<Field
							component={Fields.AsyncSelect}
							isClearable={true}
							isSearchable={true}
							cacheOptions={false}
							onChange={() => {
								setFieldValue("service_ids", null);
							}}
							isDisabled={!get(values, "service_parent")}
							optionLabel={option => {
								return `${get(option, "name.uz")}`;
							}}
							name="service_child"
							size="large"
							placeholder="Не задано"
							loadOptionsUrl={`category/${get(
								values,
								"service_parent.id"
							)}/child`}
							loadOptionsParams={search => ({
								filter: { name: search }
							})}
						/>
					</label>
				</div>

				<div className="col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<label className="input__wrapper select__wrapper caller-wrapper">
						<div className="caller-name">Товары или услуги</div>
						<Field
							component={Fields.AsyncSelect}
							isSearchable={true}
							isMulti={true}
							isDisabled={!get(values, "service_child")}
							optionLabel={option => {
								return `${get(
									option,
									"name.uz"
								)} <span style="margin-left:10px; white-space: nowrap;">${helpers.formatCurrency(
									get(option, "price") || 0,
									"."
								)} ${get(option, "currency.title.ru") ||
									"СУМ"}</span>`;
							}}
							name="service_ids"
							size="large"
							placeholder="Не задано"
							customParams={{ include: ["currency"] }}
							loadOptionsUrl={"/service"}
							loadOptionsParams={search => {
								return {
									filter: {
										name: search,
										category_id: get(
											values,
											"service_child.id"
										)
									}
								};
							}}
						/>
					</label>
				</div>

				<div className="col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<label className="caller-label">
						<div className="caller-name">
							Телефон Номер{" "}
							<span style={{ color: "red", fontSize: 20 }}>
								*
							</span>
						</div>
						<Field
							component={Fields.TextInput}
							name="phone"
							type="text"
							size="large"
							className="caller-control"
							onBlur={event => fetchUser(event.target.value)}
							disabled={disabled}
						/>
					</label>
				</div>

				<div className="col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<label className="caller-label">
						<div className="caller-name">Дополнительный номер</div>
						<Field
							component={Fields.TextInput}
							name="additional_number"
							type="text"
							size="large"
							className="caller-control"
							disabled={disabled}
						/>
					</label>
				</div>

				<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<label className="input__wrapper select__wrapper caller-wrapper textarea">
						<div className="caller-name">Описание</div>
						<Field
							component={Fields.Textarea}
							name="comment"
							type="text"
							size="large"
							className="caller-textarea"
							disabled={disabled}
						/>
					</label>
				</div>
			</div>
		</>
	);
};

export default ModalFormLegal;
