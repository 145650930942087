import React, { lazy, Suspense, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { ToastContainer } from "react-toastify";
import { history } from "store";
import ScrollTop from "./hoc/ScrollTop";
import { constants, WS_connect } from "services";
import { BillingError } from "components";
import { Layout, Spinner } from "components";
import CustomerHandler from "./pages/Customer/Customer.Handler";
import App from "./App";
import Plugins from "pages/Plugins/Plugins";
import actions from "store/actions";
import MicrophoneCheck from "./components/Modal/MicrophoneCheck";
import useOnlineStatus from "./hooks/useOnlineStatus.jsx";
import CustomerViewNew from "./pages/Customer/CustomerViewNew";

const Analytics = lazy(() => import("./pages/Analytics"));
const CallCenter = lazy(() => import("./pages/CallCenter"));
const AllHistory = lazy(() => import("./pages/Handbook"));
const CustomerView = lazy(() => import("./pages/CallCenter/CustomerView"));
const Patients = lazy(() => import("./pages/Patients"));
const Profile = lazy(() => import("./pages/Profile"));
const OrganizationNumber = lazy(() => import("./pages/OperatorNumber"));
const Source = lazy(() => import("./pages/Source"));
const Service = lazy(() => import("./pages/Service"));
const ApplicationView = lazy(() => import("pages/Task/ApplicationView"));
const OperatorStatistics = lazy(() => import("./pages/Analytics/Operator"));
const TaskList = lazy(() => import("./pages/Task/TaskList"));
const Notifications = lazy(() => import("./pages/Task/Notifications"));
const Reasons = lazy(() => import("./pages/Reasons"));
const Category = lazy(() => import("./pages/Category"));
const SubCategory = lazy(() => import("./pages/Subcategory"));
const Currency = lazy(() => import("./pages/Currency"));

const Login = lazy(() => import("./pages/Auth/Login.jsx"));

const Test = lazy(() => import("./pages/Task/test"));

const Dashboard = lazy(() => import("./pages/Dashboard"));

const routes = [
	{ path: "/", exact: true, component: CallCenter },
	{ path: "/dashboard", exact: true, component: Dashboard },
	{ path: "/handbook", exact: true, component: AllHistory },
	{ path: "/customer", exact: true, component: Patients },
	{ path: "/customer/:customerId", exact: true, component: CustomerView },
	{ path: "/customer/view/:customerId", exact: true, component: CustomerViewNew },
	{ path: "/application", exact: true, component: TaskList },
	{ path: "/trade", exact: true, component: TaskList },
	{ path: "/tasks", exact: true, component: TaskList },
	{ path: "/application/:id", exact: true, component: ApplicationView },
	{
		path: "/test",
		exact: true,
		component: Test
	}
];

const auth_routes = [{ path: "/", exact: true, component: Login }];

export default () => {
	const dispatch = useDispatch();
	const {
		isAuthenticated,
		isFetched,
		user,
		billingError,
		billingStatus
	} = useSelector(state => state.auth);

	const token = localStorage.getItem("token");
	const isOnline = useOnlineStatus();

	window.callReceived = () => {
		dispatch(actions.modal.success());
	};
	useEffect(() => {
		if (get(user, "operator_number") && isAuthenticated) {
			console.log("I am running WS_connect via routes.js");
			WS_connect(user.operator_number);
		}
	}, [user]);

	useEffect(() => {
		if (!get(user, "operator_number") && window.sipService) {
			window.sipService.stop();
			window.sipService.closeSocket();
			window.sipService = null;
		}
	}, [user]);

	if (!isOnline)
		return (
			<BillingError
				title={"Проблема с интернетом"}
				subtitle={
					"Кажется, у вас проблемы с подключением к Интернету. Пожалуйста, проверьте подключение к Интернету и обновите страницу."
				}
			/>
		);

	return (
		<Router {...{ history }}>
			<App>
				<audio id="remote-audio" hidden={true} />
				<audio id="local-audio" hidden={true} />
				<audio
					id="ring-income-audio"
					muted
					src={require("assets/audio/ringing.mp3")}
					hidden
					loop
				/>
				<audio
					id="ring-outgo-audio"
					muted
					src={require("assets/audio/outbound-call.mp3")}
					hidden
					loop
				/>
				<audio
					id="hang-up-audio"
					src={require("assets/audio/hang-up.mp3")}
					hidden
				/>
				<>
					{isAuthenticated &&
						isFetched &&
						(billingError &&
						billingError.message ===
							"NetworkError when attempting to fetch resource." ? (
							<BillingError
								title={"Проблема с интернетом"}
								subtitle={
									"Кажется, у вас проблемы с подключением к Интернету. Пожалуйста, проверьте подключение к Интернету и обновите страницу."
								}
							/>
						) : (
							// !(
							// 		billingStatus &&
							// 		get(billingStatus, "has_access", false)
							//   ) ? (
							// 	<BillingError
							// 		list={[
							// 			"На способе оплаты может быть недостаточно денег.",
							// 			"Способ оплаты больше не действителен.",
							// 			"Ваш банк не одобрил ежемесячный платеж.",
							// 			"Ваш банк не одобрил ежемесячный платеж.",
							// 			"Платежные данные в вашем аккаунте mecall не соответствуют "
							// 		]}
							// 		manager={get(billingStatus, "manager", {
							// 			phone: "+998946168616"
							// 		})}
							// 	/>
							// ) :
							<Layout>
								<Suspense fallback={<Spinner />}>
									<Switch>
										{routes.map((route, key) => (
											<Route
												key={key}
												path={route.path}
												component={ScrollTop(
													route.component
												)}
												exact={route.exact}
											/>
										))}
										{(get(user, "role") ===
											constants.ROLE_MANAGER ||
											get(user, "role") ===
												constants.ROLE_ADMIN) && (
											<>
												<Route
													path="/reasons"
													component={Reasons}
													exact={true}
												/>
												<Route
													path="/notifications"
													component={Notifications}
													exact={true}
												/>
												<Route
													path="/operator-statistics"
													component={ScrollTop(
														OperatorStatistics
													)}
													exact={true}
												/>
												<Route
													path="/analytice"
													component={ScrollTop(
														Analytics
													)}
													exact={true}
												/>
												<Route
													path="/profile"
													component={ScrollTop(
														Profile
													)}
													exact={true}
												/>
												<Route
													path="/organization-number"
													component={ScrollTop(
														OrganizationNumber
													)}
													exact={true}
												/>
												<Route
													path="/source"
													component={ScrollTop(
														Source
													)}
													exact={true}
												/>
												<Route
													path="/service"
													component={ScrollTop(
														Service
													)}
													exact={true}
												/>
												<Route
													path="/category"
													component={ScrollTop(
														Category
													)}
													exact={true}
												/>
												<Route
													path="/sub-category"
													component={ScrollTop(
														SubCategory
													)}
													exact={true}
												/>
												<Route
													path="/plugins"
													component={ScrollTop(
														Plugins
													)}
													exact={true}
												/>
												<Route
													path="/currency"
													component={ScrollTop(
														Currency
													)}
													exact={true}
												/>
											</>
										)}
										<Redirect from="*" to="/" />
									</Switch>
									<CustomerHandler />
									<MicrophoneCheck />
								</Suspense>
							</Layout>
						))}

					{((!isAuthenticated && isFetched) || !token) && (
						<Suspense fallback={<Spinner />}>
							<>
								{auth_routes.map((route, key) => (
									<Route
										key={key}
										path={route.path}
										component={ScrollTop(route.component)}
										exact={route.exact}
									/>
								))}
								<Redirect from="*" to="/" />
							</>
						</Suspense>
					)}
					{!isFetched && token && <Spinner />}
					<ToastContainer
						position="top-right"
						autoClose={false}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</>
			</App>
		</Router>
	);
};
