import React, { useState } from "react";

function UseDisclosure(initialState = false , onOpen, onClose) {
	const [isOpened,setIsOpened] = useState(initialState);

	const handleOpen = () => {
		setIsOpened(true);
		if(onOpen && typeof onOpen === 'function')
		{
			onOpen();
		}
	}

	const handleClose = () => {
		setIsOpened(false);
		if(onOpen && typeof onOpen === 'function')
		{
			onClose();
		}
	}
	const toggle = () => {
		setIsOpened(prevIsOpened => !prevIsOpened);
		if (isOpened && typeof onClose === 'function') {
			onClose();
		} else if (!isOpened && typeof onOpen === 'function') {
			onOpen();
		}
	};

	return [
		isOpened,
		{
			handleOpen,
			handleClose,
			toggle
		}
	]
}

export default UseDisclosure;