import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import get from "lodash/get";
import { helpers, time } from "services";
import { CallStatus } from "components";
import { toast } from "react-toastify";

import EntityContainer from "modules/entity/containers";

import call from "assets/images/svg/call-manual.svg";
import callDark from "assets/images/svg/call-dark.svg";

export const ApplicationCallHistoryCard = ({ taskInfo }) => {
	const { customerId } = useParams();
	const [refetch, setRefetch] = useState(0);
	const modalTrigger = useSelector(state => get(state, "modal.refetch"));

	return (
		<>
			<div className="app-view__content-main">
				<EntityContainer.All
					entity="task-log"
					name="task-log/list"
					url={`/call-history`}
					primaryKey="id"
					params={{
						sort: "id",
						refetch,
						modalTrigger,
						include:
							"callHistory.customer.source,callHistory.operator,callHistory",
						filter: {
							customer_id: customerId,
						}
					}}>
					{({ items }) => {
						return items.map(taskLog => (
							<div className="app-view__content-item d-flex justify-content-between align-items-center">
								<div className="app-view__call-status">
									<CallStatus
										status={get(
											taskLog,
											"status",
											7
										)}
									/>
								</div>

								<div className="app-view__content-item-main d-flex align-items-center justify-content-start col-8">
									<div className="app-view__content-subtext">
										<p className="app-view__content-item-text">
											{get(
												taskInfo,
												"phone",
												"-"
											)}
										</p>
										<p className="app-view__content-item-date">
											{time.to(
												get(taskLog, "created_at"),
												"DD.MM.YYYY hh:mm"
											)}
										</p>
									</div>
									<p className="app-view__content-item-text">
										{get(taskInfo, "name", "-")}
									</p>
									<p className="app-view__content-item-text">
										{helpers.formatSeconds(
											Number(
												get(
													taskLog,
													"duration",
													"-:-"
												)
											)
										)}
									</p>
								</div>
								<div className="call-user d-flex justify-content-end col-2">
									<button
										className="app-view__content-btn"
										onClick={() => {
											if (
												get(
													taskInfo,
													"phone",
													""
												)
											) {
												window.makeCallFromOutside(
													String(
														get(
															taskInfo,
															"phone",
															""
														)
													).replace(/\D/g, "")
												);
											} else {
												toast.error(
													"Номер отсутствует",
													{
														autoClose: 5000
													}
												);
											}
										}}>
										<img
											className="app-view__content-img"
											src={callDark}
											alt="call"
										/>
										Позвонить
									</button>
								</div>
							</div>
						));
					}}
				</EntityContainer.All>
			</div>
		</>
	);
};
