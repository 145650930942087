// import axios from "axios";

import { takeEvery, call, put, all } from "redux-saga/effects";
import { api, constants, queryBuilder, storage } from "services";

import authActions from "../actions/auth";
import config from "config";
import { get } from "lodash";



export function* GetMeRequest() {
	try {
		const { data } = yield call(
			api.request.get,
			queryBuilder("/user/get-me", {
				include: "notifications"
			})
		);
		yield put(authActions.GetMeRequest.success(data.data));
	} catch (error) {
		yield put(
			authActions.GetMeRequest.failure({
				error
			})
		);
	}
}

export function* CheckBilling() {
	try {
		const response = yield call(fetch, config.BILLING_URL,{
			method: "POST",
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
				"Accept": "application/json",
			},
			body: JSON.stringify({
				"token": config.BILLING_TOKEN,
			})
		});
		const data = yield response.json();
		yield put(authActions.CheckBilling.success(get(data, "data" , get(data, "error"))));
	} catch (error) {
		api.request
			.post("/user/change-status", { type: constants.TYPE_NOT_WORKING })
			.catch(e => {console.log(e)});
			yield put(
			authActions.CheckBilling.failure({
				error,
				message:String(error.message) || "error" 
			})
		);
	}
}

export function* LogoutRequest() {
	yield call(storage.remove, "token");
}

export default function* root() {
	yield all([
		takeEvery(authActions.GetMeRequest.TRIGGER, GetMeRequest),
		takeEvery(authActions.Logout.REQUEST, LogoutRequest),
		takeEvery(authActions.CheckBilling.REQUEST, CheckBilling)
	]);
}
