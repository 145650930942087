import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

function UseTimeTracker() {
	const [formattedTime, setFormattedTime] = useState('00:00');

	const isRunningRef = useRef(false);
	const timerRef = useRef(null);
	const startTimeRef = useRef(null);
	const elapsedTimeRef = useRef(0);

	const start = useCallback(() => {
		if (!isRunningRef.current) {
			startTimeRef.current = Date.now();
			isRunningRef.current = true;
		}
	},[])

	const stop = useCallback(()=>{
			if (isRunningRef.current) {
				clearInterval(timerRef.current);
				isRunningRef.current = false;
			}
	},[])

	const reset = useCallback(()=>{
		elapsedTimeRef.current = 0;
		const formatted = formatTime(elapsedTimeRef.current);
		setFormattedTime(formatted);

		isRunningRef.current = false;

		clearInterval(timerRef.current);
		timerRef.current = null;

		startTimeRef.current = null;




	},[])

	const formatTime = useMemo(() => {
		return (time) => {
			const minutes = Math.floor((time % 3600000) / 60000);
			const seconds = Math.floor(((time % 360000) % 60000) / 1000);
			return `${formatNumber(minutes)}:${formatNumber(seconds)}`;
		};
	}, []);

	const formatNumber = useCallback((number) => {
		return number.toString().padStart(2, '0');
	}, []);

	useEffect(() => {
		if(isRunningRef.current)
		{
			startTimeRef.current = Date.now();
			timerRef.current = setInterval(() => {
				const currentTime = Date.now();
				elapsedTimeRef.current = currentTime - startTimeRef.current;
				const formatted = formatTime(elapsedTimeRef.current);
				setFormattedTime(formatted);
			}, 1000);
		}
		else
		{
			clearInterval(timerRef.current);
		}

		return() => {
			clearInterval(timerRef.current);
		}
	}, [isRunningRef.current]);

	return { start, stop, reset , formattedTime };
}

export default UseTimeTracker;