import React from "react";
import { toast } from "react-toastify";
import { Field } from "formik";
import { get } from "lodash";
import cn from "classnames";

import EntityForm from "modules/entity/forms";

import { Fields } from "components";
import { constants, time } from "services";

export const NotificationModal = ({
	isOpen,
	isUpdate,
	handleClose,
	formData,
	taskId,
	customerId
}) => {
	return (
		<div
			className={cn("task-log", { "task-log_opened": isOpen })}
			onClick={event => {
				event.nativeEvent.stopImmediatePropagation();
			}}>
			<p className="settingsModall__text">Добавить напоминания</p>
			<EntityForm.Main
				entity="notification"
				name="notification/update"
				url={isUpdate ? `/notification/${isUpdate}` : "/notification"}
				method={isUpdate ? "put" : "post"}
				fields={[
					{
						name: "task_id",
						value: taskId
					},
					{
						name: "customer_id",
						value: customerId
					},
					{
						name: "user_id",
						type: "object",
						value: get(formData, "assigned"),
						onSubmitValue: value => get(value, "id")
					},
					{
						name: "action",
						value: constants.taskActionTypes.find(
							item => item.id === get(formData, "action")
						),
						type: "object",
						onSubmitValue: value => get(value, "id")
					},
					{
						name: "alert_at",
						type: "string",
						value: time.to(get(formData, "alert_at")),
						onSubmitValue: value => {
							return time.customTimeStamp(value);
						}
					},
					{
						name: "description",
						type: "string"
					}
				]}
				className="caller"
				onSuccess={() => {
					handleClose();
					toast.success("Успешно обновлен!", {
						autoClose: 5000
					});
				}}
				onError={() =>
					toast.error("Что то пошло не так", {
						autoClose: 5000
					})
				}>
				{() => (
					<div className="row g-4">
						<div className="col-12">
							<Field
								name="user_id"
								component={Fields.AsyncSelect}
								size="large"
								placeholder="Кому"
								isSearchable={true}
								optionLabel="full_name"
								optionValue="id"
								loadOptionsUrl="/user/operators"
								loadOptionsParams={search => ({
									filter: { name: search }
								})}
							/>
						</div>

						<div className="col-12">
							<Field
								component={Fields.FormikSelect}
								name="action"
								size="large"
								optionLabel="label"
								optionValue="id"
								options={constants.taskActionTypes}
								placeholder="Тип"
								className={{
									className: "modal-select-container",
									classNamePrefix: "modal-select"
								}}
							/>
						</div>

						<div className="col-12">
							<Field
								component={Fields.MaskInput}
								name="alert_at"
								mask="ed/nm/zyyy"
								placeholder="Дата"
								className="caller-control"
							/>
						</div>

						<div className="col-12">
							<Field
								component={Fields.Textarea}
								name="description"
								type="text"
								size="large"
								className="caller-textarea"
								placeholder="Комментарий"
							/>
						</div>

						<div className="d-flex justify-content-between align-items-centers">
							<button type="submit" className="modal-btn blue">
								Сохранить
							</button>
							<button
								type="reset"
								className="modal-btn red"
								onClick={handleClose}>
								Отменить
							</button>
						</div>
					</div>
				)}
			</EntityForm.Main>
		</div>
	);
};
