import React from "react";

import { constants } from "services";

import { ReactComponent as OutboundCallOn } from "assets/images/svg/call-inbound.svg";
import { ReactComponent as IncomeCallOn } from "assets/images/svg/call-outbound.svg";
import { ReactComponent as OutboundCallMissed } from "assets/images/svg/fi_phone-missed.svg";
import { ReactComponent as CustomerOff } from "assets/images/svg/fi_phone-off.svg";
import { ReactComponent as OutboundCallNoAnswer } from "assets/images/svg/call-outbound-no-answer.svg";

export const CallStatus = ({ status }) => {
	return (
		<>
			{status === constants.STATUS_END && <IncomeCallOn />}

			{status === constants.STATUS_SELF && <OutboundCallOn />}

			{status === constants.STATUS_NO_ANSWER && <OutboundCallMissed />}

			{status === constants.STATUS_CLIENT_BUSY && (
				<OutboundCallNoAnswer />
			)}

			{status === constants.STATUS_CLIENT_NO_ANSWER && <CustomerOff />}
		</>
	);
};
