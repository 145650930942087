import React from "react";
import Fields from "components/Fields";
import { Field } from "formik";
import get from "lodash/get";

import { toast } from "react-toastify";

import { customerTypes, genderOptions } from "services/helpers";

import ModalFormPhysical from "./Customer.FormPhysical";
import ModalFormLegal from "./Customer.FormLegal";

const ModalForm = ({
	values,
	setFieldValue,
	handleSubmit,
	handleReset,
	showHistoryCall,
	setShowHistoryCall,
	handleCloseWithoutChange,
	fetchUser,
	setCallType,
	callType,
	disabled
}) => {
	return (
		<>
			<div
				className="d-flex justify-content-center col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
				style={{
					marginBottom: "20px"
				}}>
				<div className="caller-number">{get(values, "phone")}</div>
				<div className="caller-type">
					{customerTypes.map(el => (
						<label
							className={`caller-label ${
								callType === el.value ? "active" : ""
							}`}>
							<Fields.RadioBtn
								innerText={el.label}
								name="type"
								value={el.value}
								isChecked={callType === el.value}
								isActive={callType === el.value}
								setIsChecked={setCallType}
								disabled={
									(showHistoryCall &&
										callType !== el.value) ||
									disabled
								}
							/>
						</label>
					))}
				</div>
				<div className="assign-operator" style={{marginLeft: "auto"}}>
					<Field
						name="queue_number"
						component={Fields.AsyncSelect}
						size="large"
						placeholder="Assign to"
						isSearchable={true}
						optionLabel="full_name"
						optionValue="id"
						loadOptionsUrl="/user/operators"
						loadOptionsParams={search => ({
							filter: { name: search }
						})}
					/>
				</div>
			</div>

			<div className="info-wrapper">
				{callType === 2 ? (
					<ModalFormLegal
						{...{
							values,
							setFieldValue,
							fetchUser,
							disabled
						}}
					/>
				) : (
					<ModalFormPhysical
						{...{
							values,
							setFieldValue,
							fetchUser,
							disabled
						}}
					/>
				)}
			</div>

			<div className="modal-footer">
				{!disabled && (
					<button
						type="submit"
						className="modal-btn blue"
						onClick={handleSubmit}>
						{showHistoryCall ? "Редактировать" : "Сохранить"}
					</button>
				)}

				<button
					type="reset"
					className="modal-btn red"
					data-bs-dismiss="modal"
					onClick={() => {
						handleCloseWithoutChange();
						handleReset();
						setShowHistoryCall(false);
						toast.error("Действие отменено!", {
							autoClose: 5000
						});
					}}>
					Отменить
				</button>
			</div>
		</>
	);
};

export default ModalForm;
