import React, { forwardRef, useState } from "react";
import EntityContainer from "../../modules/entity/containers";
import get from "lodash/get";
import { constants } from "../../services";

const ForwardCall = forwardRef(
	(
		{
			sipService,
			setTransferedPhone,
			transferedPhone,
			handleFocus,
			formatPhoneNumber,
			transferedPhoneType
		},
		ref
	) => {
		const makeTransferCall = phone => {
			if (phone.length > 2 && phone.length < 12) {
				// setCallStatus("Загрузка ...");
				sipService.transferCall(phone.replace(/\D/g, ""));
			}
		};

		return (
			<div className="call-forward__modal">
				<h1 className="call-forward__modal-title">
					Перенаправления звонка
				</h1>
				<h3 className="call-forward__modal-subtitle">
					Выберите опереатора или введите номер телфона для
					перенаправления звонка
				</h3>
				<div className="call-forward__modal-input">
					<div className="--left">
						{transferedPhoneType === "number" && (
							<div className="call-forward__modal-prev">+998</div>
						)}

						<input
							maxLength={11}
							ref={ref}
							onFocus={handleFocus}
							type="text"
							value={transferedPhone}
							onChange={e =>
								formatPhoneNumber(e, setTransferedPhone)
							}
							placeholder="Введите номер телефона или опереатора"
						/>
					</div>
					<button
						className={"call-keyboard__forward"}
						type={"button"}
						onClick={() => makeTransferCall(transferedPhone)}>
						<img
							src={require("assets/images/svg/call-forward-white.svg")}
							alt=""
						/>
					</button>
				</div>
				<ul className="call-forward__modal-lists">
					<EntityContainer.All
						entity="user-operators"
						name="user-operators"
						url="/user/operators">
						{({ items }) => {
							items = items.filter(
								item =>
									get(item, "lastStatus.type") ===
									constants.TYPE_AT_WORK
							);
							return (
								<>
									{(items || []).map((item, index) => (
										<li
											key={item.id}
											className="call-forward__modal-list">
											<div className="call-forward__modal-list-left">
												<h3 className="call-forward__modal-name">
													{item.username}
												</h3>
												<span
													className={
														"call-forward__modal-num"
													}>
													{item.operator_number}
												</span>
											</div>

											<button
												className={
													"call-keyboard__forward"
												}
												type={"button"}
												onClick={() =>
													makeTransferCall(
														item.operator_number
													)
												}>
												<img
													src={require("assets/images/svg/call-forward-blue-border.svg")}
													alt=""
												/>
											</button>
										</li>
									))}
								</>
							);
						}}
					</EntityContainer.All>
				</ul>
			</div>
		);
	}
);
export default ForwardCall;
