import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { constants, helpers, storage, time } from "services";
import { ButtonDownload, CallStatus, PhoneCopy, Audio } from "components";
import ColumnsCheckerModal from "./ColumnsCheckerModal";

import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

import { ReactComponent as EditIcon } from "../../assets/images/svg/edit.svg";

const CsTooltip = ({ comment }) => {
	return (
		<div
			style={{
				background: "white",
				color: "black",
				textAlign: "start",
				padding: "20px",
				borderRadius: 20,
				lineHeight: 1.2,
				boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
				overflowWrap: "break-word",
				wordWrap: "break-word"
			}}>
			{comment}
		</div>
	);
};

const AllHistoryTable = ({ tableData }) => {
	const user = useSelector(state => state.auth.user);
	const lan = useSelector(state => state.system.currentLangCode);
	const [columns, setColumns] = useState(JSON.parse(storage.get("columns")));
	const [tableColumnsModal, setTableColumnsModal] = useState(false);

	const tdRef = useRef(null);
	const toolTipRef = useRef(null);

	function check(value, el) {
		if (columns.find(c => c === value)) return el;
	}

	return (
		<div className="content-table-parent">
			<table className="content-table">
				<thead>
					<tr>
						<th className="columns_checkbox">
							<img
								onClick={() =>
									setTableColumnsModal(!tableColumnsModal)
								}
								style={{ cursor: "pointer" }}
								src={require(`assets/images/png/${
									!tableColumnsModal ? "dots" : "close"
								}.png`)}
								alt=""
								width={14}
								height={14}
							/>
							{tableColumnsModal ? (
								<ColumnsCheckerModal
									setColumns={setColumns}
									columns={columns}
								/>
							) : null}
						</th>
						{check("ID", <th>ID</th>)}
						{check("Имя", <th>Имя</th>)}
						{check("Адрес", <th>Адрес</th>)}
						{check("Оператор", <th>Оператор</th>)}
						{check(
							"Номер организации",
							<th style={{ width: "165px" }}>
								Номер организации
							</th>
						)}
						{check(
							"Телефон Номер",
							<th style={{ width: "165px" }}>Телефон Номер</th>
						)}

						{check("Комментарий", <th>Комментарий</th>)}
						{check("Канал рекламы", <th>Канал рекламы</th>)}
						{check("Причина", <th>Причина</th>)}
						{check("Дата", <th>Дата</th>)}
						{check("Длительность", <th>Длительность</th>)}
						{check("Звонок", <th>Звонок</th>)}
						<th></th>
						<th></th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{tableData.map((item, i) => {
						const {
							id,
							created_at,
							customer,
							phone,
							operator,
							comment,
							duration,
							status,
							category,
							connected_number
						} = item;
						return (
							<tr key={i}>
								<td></td>
								{check("ID", <td>{id}</td>)}
								{check(
									"Имя",
									<td>
										{get(customer, "name") || "Не задано"}
									</td>
								)}
								{check(
									"Адрес",
									<td>
										{`${get(customer, "region.name", "")} 
								${get(customer, "district.name", "")}`.length > 10
											? `${get(
													customer,
													"region.name",
													""
											  )} 
								${get(customer, "district.name", "")}`
											: "Не задано"}
									</td>
								)}
								{check(
									"Оператор",
									<td>
										<span className="table-num ">
											{get(
												operator,
												"operator_number",
												""
											) || "Не задано"}
										</span>
									</td>
								)}
								{check(
									"Номер организации",
									<td className="white-space_no-wrap">
										<PhoneCopy
											phone={helpers.formatPhoneView(
												connected_number,
												false
											)}
										/>
									</td>
								)}
								{check(
									"Телефон Номер",
									<td className="white-space_no-wrap">
										<PhoneCopy
											phone={helpers.formatPhoneView(
												phone,
												false
											)}
										/>
									</td>
								)}

								{check(
									"Комментарий",
									<td>
										<Tooltip
											style={{
												padding: 0,
												border: "none"
											}}
											interactive
											animateFill={false}
											position={"bottom-start"}
											html={
												<CsTooltip
													comment={
														comment || "Не задано"
													}
												/>
											}>
											{comment || "Не задано"}
										</Tooltip>
									</td>
								)}
								{check(
									"Канал рекламы",
									<td>
										<Tooltip
											style={{
												padding: 0,
												border: "none"
											}}
											interactive
											animateFill={false}
											position={"bottom-start"}
											html={
												<CsTooltip
													comment={
														get(
															customer,
															`source.name.uz`,
															""
														) || "Не задано"
													}
												/>
											}>
											{get(
												customer,
												"source.name.uz",
												""
											) || "Не задано"}
										</Tooltip>
									</td>
								)}
								{check(
									"Причина",
									<td>
										<Tooltip
											style={{
												padding: 0,
												border: "none"
											}}
											interactive
											animateFill={false}
											position={"bottom-start"}
											html={
												<CsTooltip
													comment={
														get(
															category,
															`name.${lan}`,
															""
														) || "Не задано"
													}
												/>
											}>
											{get(category, `name.${lan}`, "") ||
												"Не задано"}
										</Tooltip>
									</td>
								)}
								{check(
									"Дата",
									<td>
										{time.to(
											created_at,
											"DD.MM.YYYY HH:mm"
										)}
									</td>
								)}
								{check(
									"Длительность",
									<td>{helpers.formatSeconds(duration)}</td>
								)}
								{check(
									"Звонок",
									<td>
										<CallStatus status={status} />
									</td>
								)}
								{check(
									"Звонок",
									<td>
										<img
											onClick={() =>
												window.handleOpenCallHistoryReview(
													{
														...item,
														isManual: true,
														disabled: true
													}
												)
											}
											title="View customer details"
											style={{ cursor: "pointer" }}
											src={require(`assets/images/svg/eye.svg`)}
											alt=""
											width={30}
											height={30}
										/>
									</td>
								)}
								{check(
									"Звонок",
									<td>
										<div className="d-flex align-items-center">
											<Audio
												source={get(item, "src")}
												className="mr_20"
											/>

											{(get(user, "role") ===
												constants.ROLE_ADMIN ||
												get(user, "role") ===
													constants.ROLE_MANAGER) && (
												<ButtonDownload
													link={get(item, "src")}
												/>
											)}
										</div>
									</td>
								)}
								<td>
									<button
										type="button"
										className="cursor_pointer mr_10"
										onClick={() =>
											window.handleOpenCallHistory({
												...item,
												isManual: true
											})
										}
										title="Edit customer informations">
										<EditIcon />
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default AllHistoryTable;
