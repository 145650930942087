import React, { useEffect, useState } from "react";

const Switch = ({
					label,
					form,
					field,
					defaultChecked,
					onChange = () => {}
				}) => {
	const [isChecked, setIsChecked] = useState(true);

	useEffect(() => {
		setIsChecked(defaultChecked);
	}, [defaultChecked]);

	return (
		<label
			className="switch-wrapper"
			onClick={event => event.stopPropagation()}>
			<div className="switch-box">
				{label && (
					<label className="label" htmlFor="switch">
						{label}
					</label>
				)}
				<label className="switch">
					<input
						type="checkbox"
						id="switch"
						checked={isChecked}
						onChange={e => {
							form.setFieldValue(field.name, e.target.checked);
							setIsChecked(e.target.checked);
							onChange(e.target.checked);
						}}
					/>
					<span className="slider round"></span>
				</label>
			</div>
		</label>
	);
};

export default Switch;
