import React, { useState } from "react";
import cn from "classnames";
import { get } from "lodash";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { useOutsideClick } from "hooks/useOutsideClick";
import Actions from "modules/entity/actions";

import EntityContainer from "modules/entity/containers";
import { NotificationCard } from "./NotificationCard";

import "./Notification.scss";
import { ReactComponent as NoNotification } from "assets/images/svg/no-notification.svg";
import { ReactComponent as NotificationIcon } from "assets/images/svg/notification.svg";

export const Notifications = () => {
	const dispatch = useDispatch();
	const [modalTrigger, setModalTrigger] = useState();
	const [notificationCount, setNotificationCount] = useState(0);
	const {
		ref,
		handleToggleVisibility,
		handleCloseMenu,
		isVisible
	} = useOutsideClick();

	const deleteNotification = id => {
		dispatch(
			Actions.Form.request({
				method: "delete",
				entity: "notification",
				name: "notification/list",
				id: id,
				url: `/notification/${id}`,
				deleteData: true,
				primaryKey: "id",
				cb: {
					success: () => {
						setModalTrigger(prev => !prev);

						toast.success("Успешно удалено", {
							autoClose: 5000
						});
					},
					error: error => {
						toast.error("Что-то пошло не так", {
							autoClose: 5000
						});
					},
					finally: () => {}
				}
			})
		);
	};

	return (
		<div
			className={cn("drop-down", {
				"drop-down_opened": isVisible
			})}
			ref={ref}>
			<button
				className="notification__trigger"
				onClick={event => {
					handleToggleVisibility(event);
				}}>
				<div className="notification__badge">{notificationCount}</div>
				<NotificationIcon />
			</button>

			<div className="drop-down__inner">
				<div className="blackboard__actions">
					<EntityContainer.All
						entity="notification"
						name="notification/list"
						url="/user/notification"
						params={{
							modalTrigger,
							include: "customer",
							limit: 50
						}}
						primaryKey="id"
						onSuccess={response => {
							setNotificationCount(get(response, "data.length"));
						}}>
						{({ items }) => (
							<>
								{!items.length && (
									<div className="no-notification">
										<NoNotification />У вас нет
										непрочитанных уведомлений Удачного дня.
									</div>
								)}

								{items.map(item => (
									<NotificationCard
										key={item.id}
										id={item.id}
										deleteNotification={deleteNotification}
										userFullName={get(
											item,
											"customer.name"
										)}
										userPhone={get(item, "customer.phone")}
										comment={get(item, "description")}
										date={get(item, "alert_at")}
										handleMenuClose={handleCloseMenu}
									/>
								))}
							</>
						)}
					</EntityContainer.All>
				</div>
			</div>
		</div>
	);
};
