import React from "react";
import { get } from "lodash";
import "./BillingError.scss";

export const BillingError = ({
	manager = null,
	title = "Ваш аккаунт заблокирован из-за проблем с последним платежом",
	subtitle = "Это означает, что mecall не удалось списать оплату за ваш аккаунт. Ниже приведено несколько причин, по которым это могло произойти.",
	list
}) => {
	return (
		<div
			style={{
				background: `url(${require("assets/images/png/billing.png")}) no-repeat center center`,
				backgroundSize: "cover"
			}}
			className="billing-error">
			<div className="billing-error__inner">
				<h1 className="billing-error__title">{title}</h1>
				<div className="billing-error__subtitle">
					<h3>{subtitle}</h3>
					{list && (
						<ul>
							{list.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
					)}
				</div>
				{manager && (
					<a
						href={`tel:${get(manager, "phone", "")}`}
						className="billing-error__close">
						Связаться с нами
					</a>
				)}
				{!manager && (
					<button
						onClick={() => window.location.reload()}
						className="billing-error__close">
						Обновить страницу
					</button>
				)}
			</div>
		</div>
	);
};
