import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import get from "lodash/get";
import { api, constants, queryBuilder, time } from "services";
import { CallStatus } from "components";
import { toast } from "react-toastify";
import { Audio } from "components";
import { ApplicationViewSendMessage } from "pages/Task/components/ApplicationViewSendMessage";

import EntityContainer from "modules/entity/containers";

export const ApplicationCard = ({ taskInfo, taskLog }) => {
	const { customerId } = useParams();
	const [refetch, setRefetch] = useState(0);
	const [tasks, setTasks] = useState([]);
	const modalTrigger = useSelector(state => get(state, "modal.refetch"));
	const history = useHistory();

	useEffect(() => {
		api.request.get(
			queryBuilder("/task", {
				filter: {
					customer_id: customerId,
				},
				include: "assigned",
				extra: {
					sort: "-id"
				}
			})
		)
			.then(({ data }) => {
				setTasks(data.data)
			})
	}, [modalTrigger]);

	console.log(taskLog);

	return tasks.map((value, index) => {
		return (
			<div className="application-card"
				onClick={() => {
					history.push(`/application/${value.id}`);
				}}
			>
				<div className="application-card__header d-flex justify-content-between">
					<div className="application-card__id">
						{`# ${get(value, "id")} - ${get(
							value,
							"title",
							"Категория не выбран "
						)}`}
					</div>
					<div className="application-card__operator">
						{get(
							value,
							"assigned.full_name",
							"Оператор не выбран"
						)}
					</div>
				</div>

				<div className="application-card__info d-flex">
					<div>
						<p className="application-card__info-name">
							Время звонка
						</p>
						<p className="application-card__info-data">
							{time.to(
								get(value, "created_at"),
								"DD.MM.YYYY hh:mm"
							)}
						</p>
					</div>
					<div>
						<p className="application-card__info-name">
							Категория
						</p>
						<p className="application-card__info-data">
							{get(
								value,
								"title",
								"-"
							)}
						</p>
					</div>
					<div>
						<p className="application-card__info-name">
							Подкатегория
						</p>
						<p className="application-card__info-data">
							{
								get(
									value,
									"services[0].category",
								"-")
							}
						</p>
					</div>
					<div>
						<p className="application-card__info-name">
							Товар или услуги
						</p>
						<p className="application-card__info-data">
							{get(value, "service[0].name", "-")}
						</p>
					</div>
				</div>

				<div className="application-card__status">
					<div className="status-item">
						<p className="status-item__name">
							1. Оператор
						</p>
						<p className="status-item__date">
							{time.to(
								get(value, "created_at"),
								"DD.MM.YYYY hh:mm"
							)}
						</p>
					</div>
					<div className="status-item">
						<p className="status-item__name">
							2. Менеджер
						</p>
						<p className="status-item__date">
							{time.to(
								get(value, "updated_at"),
								"DD.MM.YYYY hh:mm"
							)}
						</p>
					</div>
					<div className="status-item">
						<p className="status-item__name">
							3. Модератор
						</p>
						<p className="status-item__date">
							{time.to(
								get(value, "updated_at"),
								"DD.MM.YYYY hh:mm"
							)}
						</p>
					</div>
					<div className="status-item">
						<p className="status-item__name">
							4. Админстратор
						</p>
						<p className="status-item__date">
							{time.to(
								get(value, "updated_at"),
								"DD.MM.YYYY hh:mm"
							)}
						</p>
					</div>
				</div>
			</div>
		)
	});

};
