import React from "react";
import get from "lodash/get";

const RadioBtn = ({
	isChecked,
	setIsChecked,
	value,
	isActive,
	innerText,
	disabled,
	setFieldValue,
	...props
}) => {
	return (
		<label className={`radio ${isActive ? "active" : ""}`}>
			{/* <span className="radio_icon" /> */}
			<input
				style={{cursor: "pointer"}}
				type="radio"
				className="radio-input"
				checked={isChecked}
				disabled={disabled}
				onChange={() => {
					if(setIsChecked){
						setIsChecked(value)
					} else if(setFieldValue){
						setFieldValue(get(props, 'name', 'isChecked'), value)
					}
				}}
				{...props}
			/>
			{innerText}
		</label>
	);
};

export default RadioBtn;
