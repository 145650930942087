import React from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "./newDatePicker.scss";
function NewDatePicker({ value, onChange }) {
	const containerRef = React.useRef(null);
	return (
		<div
			ref={containerRef}
			style={{
				display: "flex",
				width: "max-content",
				height: 40,
				padding: "4px 12px",
				borderRadius: 4,
				border: "1px solid #eaedf0",
				background: "#fff",
				position: "relative",
				zIndex: 1
			}}
			className="new_date_picker-container">
			<DateRangePicker
				value={value}
				onChange={onChange}
				format="dd/MM/yyyy"
				clearIcon={null}
				formatShortWeekday={(locale, value) =>
					["S", "M", "T", "W", "T", "F", "S"][value.getDay()]
				}
			/>
		</div>
	);
}

export default NewDatePicker;
