/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import EntityContainer from "modules/entity/containers";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { get } from "lodash";

import { constants } from "services";
import settings from "assets/images/svg/settings.svg";
import Modal from "components/Modal";
import SettingsModal from "components/Modal/components/SettingsModal/SettingsModal";
import { useModal } from "hooks/useModal";

const SideBar = () => {
	const user = useSelector(state => state.auth.user);
	const { isOpen, handleClose, handleOpen } = useModal();

	return (
		<section className="sidebar">
			<div className="sidebar__inner">
				{/*<Link to="/" className="sidebar-logo">*/}
				{/*	<img src={require("assets/images/svg/logo.svg")} alt="" />*/}
				{/*</Link>*/}

				{get(user, "role") === constants.ROLE_ADMIN && (
					<ul className="sidebar-list">
						<EntityContainer.All
							entity="user-operators"
							name="user-operators"
							url="/user/operators">
							{({ items }) => (
								<>
									{(items || []).map((item, index) => {
										if (
											item.username ===
											get(user, "username")
										) {
											return null;
										}
										return (
											<li key={item.id}>
												<a
													title={
														"Оператор " +
														item.username
													}
													className={cn(
														"sidebar-link",
														{
															active:
																item.username ===
																get(
																	user,
																	"username"
																),
															working:
																get(
																	item,
																	"lastStatus.type"
																) ===
																constants.TYPE_AT_WORK,
															"not-working":
																get(
																	item,
																	"lastStat.type"
																) !==
																constants.TYPE_AT_WORK
														}
													)}>
													{item.username}
												</a>
											</li>
										);
									})}
								</>
							)}
						</EntityContainer.All>
					</ul>
				)}
				<div className="sidebar__control">
					<button
						title="Добавить Историю"
						className="sidebar-btn"
						onClick={() => window.handleOpen({ isManual: true })}>
						<img
							src={require("assets/images/svg/tel.svg")}
							alt=""
						/>
					</button>
					{(get(user, "role") === constants.ROLE_MANAGER ||
						get(user, "role") === constants.ROLE_ADMIN) && (
						<>
							{/**main-call-btn */}
							<button
								title="Настройки"
								className="sidebar-btn"
								onClick={handleOpen}>
								<img src={settings} alt="settings" />
							</button>
							<Modal
								isOpen={isOpen}
								size={700}
								toggle={() => {}}
								customClass="modal-test">
								<SettingsModal handleClose={handleClose} />
							</Modal>
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export default SideBar;
